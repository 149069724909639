<template>
  <div class="loginPage">
    <div class="container">
      <!-- 右侧登录框 -->
      <div class="loginBox">
        <img src="../assets/static/login-logo.png" alt="" />
        <!--验证码登录-->
        <el-form
          :model="codeForm"
          :rules="codeRule"
          v-show="loginType == 'code'"
          ref="codeForm"
          label-width="234px"
        >
          <el-form-item label="" prop="phone">
            <el-input
              v-model="codeForm.phone"
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="verifyCode"
            style="display:flex;margin-bottom: 10px;"
          >
            <el-input
              v-model="codeForm.verifyCode"
              style="width:110px;margin-right:10px;"
              placeholder="验证码"
            ></el-input>
            <el-button
              type="primary"
              style="width:110px;"
              @click="getVerifyCode"
              :disabled="!verifyCooling"
              >{{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                  ? "发送验证码"
                  : `${verifyCoolingTime}秒重新获取`
              }}</el-button
            >
          </el-form-item>
          <div class="codeLogin" @click="loginType = 'password'">密码登录</div>
          <el-form-item>
            <el-button
              type="primary"
              @click="codeLogin('codeForm')"
              :loading="loading"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$router.push('/register')"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
        <!--密码登录-->
        <el-form
          :model="loginForm"
          :rules="loginRule"
          v-show="loginType == 'password'"
          ref="loginForm"
          label-width="234px"
        >
          <el-form-item label="" prop="username">
            <el-input
              v-model="loginForm.username"
              prefix-icon="el-icon-user"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px;" label="" prop="password">
            <el-input
              v-model="loginForm.password"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              type="password"
              @keyup.enter.native="login('loginForm')"
            ></el-input>
          </el-form-item>
          <!-- <div class="codeLogin" @click="loginType = 'code'">验证码登录</div> -->
          <el-form-item>
            <el-button
              style="margin-top: 20px;"
              type="primary"
              @click="login('loginForm')"
              :loading="loading"
              >登录</el-button
            >
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="$router.push('/register')"
              >注册</el-button
            >
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { sendCode } from "@/api/register";
import verify from "@/utils/verify";
import { login, codeLogin } from "@/api/login";
import { mapGetters, mapMutations } from "vuex";
import { _setRoleId, _setRouterList, _setParentRoleId } from "@/utils/storage";
import { _generateRoute } from "@/utils/generateRouter";
import basicMixin from "@/mixins/basic";
import md5 from "js-md5";
export default {
  mixins: [basicMixin],
  data() {
    return {
      loading: false, //是否正在登录中
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      loginType: "password", // 登录方式
      loginForm: {
        username: "",
        password: "",
      },
      codeForm: {
        verifyCode: "",
        phone: "",
      },
      loginRule: {
        username: [
          { required: true, message: "请输入正确的用户名", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
      codeRule: {
        username: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getToken", "getName", "getPhone"]),
  },
  methods: {
    ...mapMutations(["setToken", "setName", "setPhone", "setFullInfo"]),
    //登录
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            username: this.loginForm.username,
            password: md5(String(this.loginForm.password)),
          };
          login(params)
            .then((res) => {
              //设置右上角用户名
              this.setName(res.data.chineseName);
              // 存储手机号
              this.setPhone(res.data.name);
              // 设置货主服务商信息页面填写状态
              this.setFullInfo(0);
              //路由列表存到sessionStorage中，防止刷新丢失路由列表
              _setRouterList(res.data.menus);
              //如果账号密码对了那就把token存起来再去首页
              this.setToken(res.data.token);
              //角色id存起来 1平台 2货主 3服务商
              _setRoleId(res.data.roleid);
              //父角色id
              _setParentRoleId(res.data.parentRoleId);
              //生成路由列表
              // _generateRoute(this.$router, res.data.menus);
              // ${res.data.menus[0].children[1].Path}
              localStorage.setItem("isAdmin", res.data.isAdmin);
              if (res.data.menus.length > 0) {
                const str = `${res.data.menus[0].children[0].Path}`;
                this.$router.push(str);
              } else {
                this.$router.push("/power/nopermission");
              }
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // 验证码登录
    codeLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            phone: this.codeForm.phone,
            code: this.codeForm.verifyCode,
          };
          codeLogin(params)
            .then((res) => {
              //设置右上角用户名
              this.setName(res.data.ChineseName);
              // 存储手机号
              this.setPhone(res.data.name);
              // 设置货主服务商信息页面填写状态
              this.setFullInfo(0);
              //路由列表存到sessionStorage中，防止刷新丢失路由列表
              _setRouterList(res.data.menus);
              //如果账号密码对了那就把token存起来再去首页
              this.setToken(res.data.token);
              //角色id存起来 1平台 2货主 3服务商
              _setRoleId(res.data.roleid);
              //父角色id
              _setParentRoleId(res.data.parentRoleId);

              //生成路由列表
              // _generateRoute(this.$router, res.data.menus);
              this.$router.push("/");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    //发送验证码
    getVerifyCode() {
      //没填写手机号提示填写手机号
      if (!this.codeForm.phone) {
        this.$message.warning("请填写接收验证码的手机号");
        return;
      }
      //正在倒计时中直接return
      else if (!this.verifyCooling) return;
      if (!verify._phone(this.codeForm.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.verifyCooling = false;
      this.verifyCoolingTime = this.verifyCoolingTime - 1;
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true;
          this.verifyCoolingTime = 60;
          clearInterval(timer);
        }
      }, 1000);
      //发送验证码
      const params = {
        phone: this.codeForm.phone,
      };

      sendCode(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("验证码发送成功");
        }
      });
    },
  },
  created() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
};
</script>

<style scoped lang="scss">
.loginPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/static/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .codeLogin {
    color: #cccccc;
    text-align: right;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .container {
    padding: 80px 64px;
    background-color: white;
    position: absolute;
    top: 50%;
    right: 14%;
    transform: translate(14%, -50%);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    display: flex;
    overflow: hidden;
    .loginBox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 230px;
        margin-bottom: 8px;
      }
      .el-form {
        width: 230px;
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.loginBox .el-form .el-form-item__content {
  margin: 0 !important;
}
.loginPage {
  .el-button--primary {
    background-color: #2081ff;
  }
}
</style>
