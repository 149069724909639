// 纳税人识别号正则
const _identify = (text) => {
    let identify = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
    if(!identify.test(text)){
        return false
    }else{
        return true
    }
}

// 手机号正则
const _phone = (phone) => {
    let regTel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; 
    if(!regTel.test(phone)){
        return false
    }else{
        return true
    }
}

// 身份证正则
const _isCardNo = (cardNo) => {
    let regCardNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
    if(!regCardNo.test(cardNo)){
        return false
    }else{
        return true
    }
}

// 只能输入整数或小数的正则
const _integer = (num) => {
    let int = /^\d+(\.\d{0,2})?$/; 
    if(!int.test(num)){
        return false
    }else{
        return true
    }
}

// 判断是否输入小数点  最多支持整数后两位
const _radix = (num) => {
    let radixPoint = String(num).indexOf('.') + 1
    let radixNum = ''
    if(radixPoint > 0){
        if(num.length == 4){
            radixNum = num
        }else if(num.length == 3){
            radixNum = `${num}0`
        }else if(num.length == 2){
            radixNum = `${num}00`
        }
    }else{
        radixNum = `${num}.00`
    }
    return radixNum
}

export default {
    _identify,
    _phone,
    _isCardNo,
    _integer,
    _radix
}